<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/user.png" />
          </span>
          <strong>{{$t('sabre.booking.payer-box.payer-detail')}}</strong>
        </div>
      </h3>
    </div>
    <div class="flightbooking_form_middel">
      <div class="flightformbox_itembox">
        <div class="row g-3">
          <div class="col-lg-6">
            <form-control
              controlType="text"
              v-model="firstName"
              :placeholder="$t('sabre.booking.passenger-box.name')"
              :disabled="disabled"
              :label="$t('sabre.booking.passenger-box.first-name')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-name')"
            />
          </div>
          <div class="col-lg-6">
            <form-control
              controlType="text"
              v-model="lastName"
              :placeholder="$t('sabre.booking.passenger-box.last-name')"
              :disabled="disabled"
              :label="$t('sabre.booking.passenger-box.last-name')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-name')"
            />
          </div>
          <div class="col-lg-6">
            <form-control
              controlType="email"
              v-model="email"
              :placeholder="$t('sabre.booking.passenger-box.email')"
              :disabled="disabled"
              :label="$t('sabre.booking.passenger-box.email')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-email')"
            />
          </div>
          <div class="col-lg-6">
            <form-control
              controlType="number"
              v-model="mobile"
              :placeholder="$t('sabre.booking.passenger-box.phone-number')"
              :disabled="disabled"
              :label="$t('sabre.booking.passenger-box.phone-number')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-phone')"
            />
          </div>
          <div class="col-lg-6">
            <form-control
              controlType="number"
              v-model="idNumber"
              :placeholder="$t('sabre.booking.payer-box.id')"
              :disabled="disabled"
              :label="$t('sabre.booking.payer-box.id')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-id')"
            />
          </div>
          <div class="col-lg-6">
            <form-control
              controlType="select"
              :options="selectOptions"
              v-model="numberOfPayments"
              :placeholder="$t('sabre.booking.payer-box.number-of-payment')"
              :disabled="disabled"
              :label="$t('sabre.booking.payer-box.number-of-payment')"
              :invalidFeedback="$t('sabre.booking.payer-box.invalid-feedback-payment-number')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'payer-detail-form',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormControl: () => import('../passengerFormItem/formControl'),
  },
  computed: {
    ...mapGetters({
      payerForm: 'GET_SABRE_PAYER_INFO',
      isDevelopMode: 'GET_MODE',
    }),
  },
  watch: {
    firstName() {
      this.update();
    },
    lastName() {
      this.update();
    },
    email() {
      this.update();
    },
    mobile() {
      this.update();
    },
    idNumber() {
      this.update();
    },
    numberOfPayments() {
      this.update();
    },
  },
  data() {
    return {
      selectOptions: [
        { text: '1 תשלום', value: '0' },
        { text: 'One', value: '1' },
        { text: 'Two', value: '2' },
        { text: 'Three', value: '3' },
      ],
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      idNumber: '',
      numberOfPayments: '',
    };
  },
  mounted() {
    const tempData = this.isDevelopMode ? {
      firstName: 'test',
      lastName: 'test',
      email: 'test@test.com',
      mobile: '12345789',
      idNumber: '011111119',
      numberOfPayments: 0,
    } : null;
    this.firstName = tempData?.firstName || this.payerForm?.firstName || '';
    this.lastName = tempData?.lastName || this.payerForm?.lastName || '';
    this.email = tempData?.email || this.payerForm?.email || '';
    this.mobile = tempData?.mobile || this.payerForm?.mobile || '';
    this.idNumber = tempData?.idNumber || this.payerForm?.idNumber || '';
    this.numberOfPayments = tempData?.numberOfPayments || this.payerForm?.numberOfPayments || '0';
  },
  methods: {
    update() {
      const { firstName, lastName, email, mobile, idNumber, numberOfPayments } = this;
      this.$emit('update', { type: 'payer', data: { firstName, lastName, email, mobile, idNumber, numberOfPayments: +numberOfPayments } });
    },
  },
};
</script>
